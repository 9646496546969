"use client";
import Image from "next/image";
import styles from "./style.module.scss";
import AppLogo from "../../images/footer-logo.svg";
import IrdaiLogo from "../../images/irdai-logo.svg";
import linkedin from "../../../public/svg/linkedinblue.svg";
import facebook from "../../../public/svg/facebookblue.svg";
import twitter from "../../../public/svg/twitterblue.svg";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { Label } from "@cars24/turbo-web/lib/label";
import { Heading } from "@cars24/turbo-web/lib/heading";
import { FOOTER_OPTIONS, SOCIAL_MEDIA_OPTIONS } from "./constants";
import { NavigateToLink } from "@/utils/navigation";
import { isMobileView } from "@/utils/responsive";
import { useEffect, useState } from "react";
import { sendAnalyticsData } from "@/services/google-analytics";
import { AnalyticsEvents } from "@/constants/appAnalyticsConstants";
import { EXTERNAL_URLS } from "@/constants/urls.constants";

const Footer = () => {
  const router = useRouter();
  const [isMView, setIsMview] = useState(isMobileView());
  useEffect(() => {
    setTimeout(() => {
      //need to add resixe
      setIsMview(isMobileView());
    }, 1500);
  }, []);
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footerSection}>
        <div className={styles.topSection}>
          <div className={styles.footerLogoBlock}>
            <Image
              src={AppLogo}
              // width={'auto'}
              // height={35}
              alt="Insure icon"
            />
            <div className={styles.licencedBy}>
              <div className={styles.contentOpacity}>
                <Label level={3} color="var(--base-white)">
                  Licenced by
                </Label>
              </div>
              <Image
                src={IrdaiLogo}
                // width={'auto'}
                // height={35}
                alt="IRDAI icon"
              />
            </div>
          </div>
          <div className={styles.rightBlock}>
            {FOOTER_OPTIONS.map(
              (option) =>
                (!isMView || (isMView && option.mView)) && (
                  <div>
                    <div className={`${styles.optionTitle}`}>
                      <Heading level={4} color="var(--base-white)" semibold>
                        {option.label || "   "}
                      </Heading>
                    </div>
                    <div
                      className={`${styles.contentOpacity} ${styles.options}`}
                    >
                      {option.options.map(
                        (item: any, index) =>
                          (isMView || (!isMView && !item?.mView)) && (
                            <div
                              key={"option" + index}
                              className={styles.option}
                              onClick={() => {
                                item.eventName &&
                                  sendAnalyticsData(
                                    item.eventName,
                                    AnalyticsEvents.homepage.data
                                  );
                                if (item?.url) {
                                  router.push(item.url);
                                } else if (item?.link) {
                                  NavigateToLink(item.link);
                                }
                              }}
                            >
                              <Label
                                level={2}
                                color="var(--base-white)"
                                fontWeight="regular"
                              >
                                {item?.label}
                              </Label>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>

        {/* <div>
                    <div>
                        Terms and Conditions | All rights reserved by INSURE24
                    </div>
                    <div className={styles.shareIcon}>
                       
                        <div
                            onClick={() => {
                                router.push(
                                    "https://x.com/Insure_24?t=ofRscf3CsSFBxX_5VXnJLw&s=08"
                                );
                            }}
                        >
                            <Image
                                src={twitter}
                                width={15}
                                height={15}
                                alt="Insure Blog"
                            />
                        </div>
                        <div
                            onClick={() => {
                                router.push(
                                    "https://www.facebook.com/Insure24official"
                                );
                            }}
                        >
                            <Image
                                src={facebook}
                                width={15}
                                height={15}
                                alt="Insure Blog"
                            />
                        </div>
                    </div>
                </div> */}
        <div className={styles.termsSocialBlock}>
          <div className={styles.termsLicenceBlock}>
            {isMobileView() ? (
              <div className={styles.licence}>
                <Label level={3} color="var(--base-white)">
                  IRDAI Corporate Agency Registration No: CA0710 (Valid till
                  11th March 2026)
                </Label>
                <Label level={3} color="var(--base-white)">
                  License Category: Composite
                </Label>
                <Label level={3} color="var(--base-white)">
                  CIN: U65990HR2018PTC075713
                </Label>
              </div>
            ) : (
              <div className={styles.licence}>
                <Label level={3} color="var(--base-white)">
                  IRDAI Corporate Agency Registration No: CA0710 (Valid till
                  11th March 2026) | License Category: Composite |
                </Label>
                <Label level={3} color="var(--base-white)">
                  CIN: U65990HR2018PTC075713
                </Label>
              </div>
            )}
            <div className={styles.terms}>
              {isMobileView() ? (
                <div>
                  <Link href="/terms-and-conditions">
                    <Label level={3} color="var(--base-white)">
                      Terms and Conditions
                    </Label>
                  </Link>
                  <Link href={EXTERNAL_URLS.PRIVACY_POLICY} target="_blank">
                    <Label level={3} color="var(--base-white)">
                      Privacy Policy
                    </Label>
                  </Link>
                  <Label level={3} color="var(--base-white)">
                    All rights reserved by INSURE24
                  </Label>
                </div>
              ) : (
                <>
                  <Link href="/terms-and-conditions">
                    <Label level={3} color="var(--base-white)">
                      Terms and Conditions
                    </Label>
                  </Link>

                  <Label level={3} color="var(--base-white)">
                    &nbsp;|&nbsp;
                  </Label>
                  <Link href={EXTERNAL_URLS.PRIVACY_POLICY} target="_blank">
                    <Label level={3} color="var(--base-white)">
                      Privacy Policy
                    </Label>
                  </Link>
                  <Label level={3} color="var(--base-white)">
                    &nbsp;|&nbsp;
                  </Label>
                  <Label level={3} color="var(--base-white)">
                    All rights reserved by INSURE24
                  </Label>
                </>
              )}
            </div>
            <div className={styles.disclaimerText}>
              <Label level={3} color="var(--base-white)">
                Disclaimer : The information contained in this website is
                presented purely for information purposes only provided as
                service to the internet community at large. It does not
                constitute insurance advice and we do not guarantee the
                accuracy, adequacy or the completeness of the information
                contained here.
              </Label>
            </div>
          </div>

          <div className={styles.socialOptions}>
            {SOCIAL_MEDIA_OPTIONS.map((option, index) => (
              <div
                key={"footer" + index}
                onClick={() => {
                  NavigateToLink(option.link);
                }}
              >
                <Image
                  src={option.image}
                  width={40}
                  height={40}
                  alt="social media icon"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
