import InstaImg from "../../images/insta.svg";
import LinkedinImg from "../../images/linkedin.svg";
import FacebookImg from "../../images/facebook.svg";
import Twittermg from "../../images/twitter.svg";
import YoutubeImg from "../../images/youtube.svg";
import { EXTERNAL_URLS } from "@/constants/urls.constants";
import { AnalyticsEvents } from "@/constants/appAnalyticsConstants";
export const FOOTER_OPTIONS = [
  {
    label: "COMPANY",
    mView: true,
    options: [
      {
        label: "About us",
        url: "/about-us",
        eventName: AnalyticsEvents.homepage.about_us_staticpage.name,
      },
      {
        label: "Contact us",
        link: EXTERNAL_URLS.CONTACT,
        eventName: "",
      },
    ],
  },
  {
    label: "PRODUCTS",
    mView: true,
    options: [
      {
        label: "Car Insurance",
        link: EXTERNAL_URLS.CAR_INSURANCE,
        eventName:
          AnalyticsEvents.homepage.get_car_quote_staticpage_footer.name,
      },
      {
        link: EXTERNAL_URLS.TWO_WHEELER_INSURANCE,
        label: "Bike Insurance",
        eventName:
          AnalyticsEvents.homepage.get_bike_quote_staticpage_footer.name,
      },
      {
        link: EXTERNAL_URLS.CARD_PROTECT,
        label: "Card Protect",
        eventName: AnalyticsEvents.homepage.card_buynow_staticpage.name,
      },
      {
        link: EXTERNAL_URLS.UPI_PROTECT,
        label: "UPI Protect",
        eventName: AnalyticsEvents.homepage.upi_buynow_staticpage.name,
      },
      {
        link: EXTERNAL_URLS.HEALTH_GUARD_PLUS,
        label: "HealthGuard Plus",
        eventName: "",
      },
      {
        link: EXTERNAL_URLS.MOBILE_SCREEN_SECURE,
        label: "Mobile Screen Secure",
        eventName: AnalyticsEvents.homepage.mobile_buynow_staticpage.name,
      },
      {
        link: EXTERNAL_URLS.CAR_ASSISTANCE,
        label: "Car Assistance",
        eventName:
          AnalyticsEvents.homepage.car_assitance_buy_now_staticpage.name,
      },
    ],
  },
  {
    label: "",
    mView: false,
    options: [
      ,//     {
      //     link:EXTERNAL_URLS.HEALTH_GUARD_PLUS,
      //     label:"HealthGuard Plus",
      // },
      // {
      //     link:"",
      //     label:"Mobile Protect"
      // },{
      //     link:"",
      //     label:"Extended Warranty"
      // },
      // {
      //     link:EXTERNAL_URLS.CAR_ASSISTANCE,
      //     label:"Car Assistance",
      // }
    ],
  },
  // {
  //     label:'LEGAL',
  //     options:[,{
  //         link:"",
  //         label:"Policies"
  //     },{
  //         link:"",
  //         label:"Disclaimers"
  //     },{
  //         link:"",
  //         label:"Terms & Conditions"
  //     },{
  //         link:"",
  //         label:"Disclosures"
  //     }]
  // },
  // {
  //     label:'Resources',
  //     options:[,{
  //         link:"",
  //         label:"Blogs"
  //     }]
  // }
];

export const SOCIAL_MEDIA_OPTIONS = [
  {
    image: InstaImg,
    link: "https://www.instagram.com/insure24_official/?hl=en",
  },
  // {
  //     image:LinkedinImg,
  //     link:''
  // },
  {
    image: FacebookImg,
    link: "https://www.facebook.com/Insure24official",
  },
  {
    image: Twittermg,
    link: "https://x.com/Insure_24?t=ofRscf3CsSFBxX_5VXnJLw&s=0",
  },
  {
    image: YoutubeImg,
    link: "https://youtube.com/@insure_24?si=t5tq8lU78eSqdJt-",
  },
];
