"use client";
import { AnalyticsEvents } from "../constants/appAnalyticsConstants";
import TagManager from "react-gtm-module";
export const initializeAnalytics = (id) => {
  const tagManagerArgs = {
    gtmId: id,
    event: {
      login_staticpage: AnalyticsEvents.homepage.login_staticpage,
    },
  };
  TagManager.initialize(tagManagerArgs);
};

export const sendAnalyticsData = (dataLayerName, data) => {
  let reqData = { ...data, event: dataLayerName };
  TagManager.dataLayer({ dataLayer: reqData });
};
