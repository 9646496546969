"use client";
import Image from "next/image";
import Link from "next/link"; // Import Link for navigation
import { useEffect, useState, useRef } from "react";
import logoSvg from "../../../public/logo.svg";
import logoImg from "../../../public/logo.png";
import insure24 from "../../../public/svg/insure24.svg";
import insure24svg from "../../../public/svg/insure24black.svg";
import chevronup from "../../../public/svg/chevron-up.svg";
import chevronupWhite from "../../../public/svg/chevron-up-white.svg";
import chevrondownWhite from "../../../public/svg/chevron-down-white.svg";
import cars24Color from "../../../public/svg/cars24Color.svg";
import cars24 from "../../../public/svg/cars24.svg";
import chevrondown from "../../../public/svg/chevron-down.svg";
import styles from "./style.module.css";
// import Button from "../button";
import { menuItems } from "./constant";
import { Button } from "@cars24/turbo-web/lib/button";
import { NavigateToLink } from "@/utils/navigation";
import { EXTERNAL_URLS } from "@/constants/urls.constants";
import { sendAnalyticsData } from "@/services/google-analytics";
import { AnalyticsEvents } from "@/constants/appAnalyticsConstants";
interface NavbarProps {
  isSticky?: boolean;
}

export const Navbar: React.FC<NavbarProps> = ({ isSticky: propIsSticky }) => {
  const [isSticky, setIsSticky] = useState(propIsSticky || false);
  const [activeMenuItemIndex, setActiveMenuItemIndex] = useState<number | null>(
    null
  );
  const navbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (propIsSticky !== undefined) {
      setIsSticky(propIsSticky);
    }
  }, [propIsSticky]);

  useEffect(() => {
    if (propIsSticky === undefined || propIsSticky === false) {
      const handleScroll = () => {
        setIsSticky(window.scrollY > 0);
      };
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [propIsSticky]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target as Node)
      ) {
        setActiveMenuItemIndex(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMenuClick = (index: number) => {
    setActiveMenuItemIndex(activeMenuItemIndex === index ? null : index);
  };

  return (
    <div
      ref={navbarRef}
      className={`${styles.navbar} ${isSticky ? styles.sticky : ""}`}
    >
      <div className={styles.navbarFlex}>
        <Link href="/">
          <div className={styles.navbarLogo}>
            <div>
              <Image
                src={isSticky ? logoImg : logoSvg}
                width={33}
                height={35}
                alt="Insure icon"
              />
            </div>
            <div>
              <div>
                <Image
                  src={isSticky ? insure24svg : insure24}
                  width={110}
                  height={34}
                  alt="Insure logo"
                />
              </div>
              <div>
                <Image
                  src={isSticky ? cars24Color : cars24}
                  width={39}
                  height={14}
                  alt="Insure logo"
                />
              </div>
            </div>
          </div>
        </Link>
        <div className={styles.menuItems}>
          {menuItems.map((item, index) => (
            <div
              key={index}
              className={`${styles.menuItem} ${
                activeMenuItemIndex === index && item.subItems
                  ? styles.selected
                  : ""
              }`}
              onClick={() => handleMenuClick(index)}
            >
              {item.link && !item.subItems ? (
                // <Link
                //   href={item.link}
                //   className={`${styles.menuLink} ${
                //     isSticky ? styles.scrollColor : ""
                //   }`}
                //   onClick={() => {
                //     item.label === "About Us" &&
                //       sendAnalyticsData(
                //         AnalyticsEvents.homepage.about_us_staticpage.name,
                //         AnalyticsEvents.homepage.data
                //       );
                //     item.label === "Claims" &&
                //       sendAnalyticsData(
                //         AnalyticsEvents.homepage.claims_staticpage.name,
                //         AnalyticsEvents.homepage.data
                //       );
                //   }}
                // >
                //   {item.label}
                // </Link>
                <div
                  onClick={() => {
                    item.label === "About Us" &&
                      sendAnalyticsData(
                        AnalyticsEvents.homepage.about_us_staticpage.name,
                        AnalyticsEvents.homepage.data
                      );
                    item.label === "Claims" &&
                      sendAnalyticsData(
                        AnalyticsEvents.homepage.claims_staticpage.name,
                        AnalyticsEvents.homepage.data
                      );
                    NavigateToLink(item.link, "_self");
                  }}
                  className={`${styles.menuLink} ${
                    isSticky ? styles.scrollColor : ""
                  }`}
                >
                  {item.label}
                </div>
              ) : (
                <div
                  className={`${styles.menuLabel} ${
                    isSticky || activeMenuItemIndex === index
                      ? styles.scrollColor
                      : ""
                  }`}
                >
                  {item.label}
                  {item.subItems && (
                    <Image
                      src={
                        activeMenuItemIndex === index
                          ? isSticky
                            ? chevronup
                            : chevronupWhite
                          : isSticky
                          ? chevrondown
                          : chevrondownWhite
                      }
                      width={24}
                      height={24}
                      alt="Chevron icon"
                    />
                  )}
                </div>
              )}
              {item.subItems && (
                <div
                  className={`${styles.subMenu} ${
                    activeMenuItemIndex === index ? styles.isVisible : ""
                  }`}
                >
                  {item.subItems.map((subItem, subIndex) => (
                    <a
                      href={subItem.link}
                      key={subIndex}
                      className={styles.subMenuItem}
                      onClick={() => {
                        subItem.eventName &&
                          sendAnalyticsData(
                            subItem.eventName,
                            AnalyticsEvents.homepage.data
                          );
                      }}
                    >
                      {subItem.label}
                    </a>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        {/* <Button
                    text={"Get a Quote"}
                    bgColor={isSticky ? "#0463F0" : ""}
                    textColor={isSticky ? "#FFFFFF" : ""}
                    href="https://i24stage.in/car-insurance-products/"
                /> */}
        <div className={styles.rightButton}>
          <Button
            label={"Login"}
            onClick={() => {
              sendAnalyticsData(
                AnalyticsEvents.homepage.login_staticpage.name,
                AnalyticsEvents.homepage.data
              );
              NavigateToLink(EXTERNAL_URLS.USER_LOGIN);
            }}
            variant={isSticky ? "primary" : "secondary"}
          />
        </div>
      </div>
    </div>
  );
};
