
export const MOBILE_VIEW_WIDTH = 1100;
export const isMobileView = () =>{
  if (typeof window != "undefined") {
  return window.innerWidth <= 1100;
  }
  return false;
}

export const isDesktopView = () =>{
  if (typeof window != "undefined") {
  return window.innerWidth > 1100;
  }
  return true;
}