import { AnalyticsEvents } from "@/constants/appAnalyticsConstants";
import { EXTERNAL_URLS } from "@/constants/urls.constants";

interface SubItem {
    label: string;
    link: string;
    eventName: string;
}

interface MenuItem {
    label: string;
    link?: string;
    subItems?: SubItem[];
}

export const menuItems: MenuItem[] = [
    {
        label: "Motor Insurance",
        subItems: [
            {
                label: "Car Insurance",
                link: EXTERNAL_URLS.CAR_INSURANCE,
                eventName: AnalyticsEvents.homepage.get_car_quote_staticpage_header.name,
            },
            {
                label: "Bike Insurance",
                link: EXTERNAL_URLS.TWO_WHEELER_INSURANCE,
                eventName: AnalyticsEvents.homepage.get_bike_quote_staticpage_header.name,
            },
        ],
    },
    {
        label: "Pocket Insurance",
        subItems: [
            {
                label: "UPI Protect",
                link: EXTERNAL_URLS.UPI_PROTECT,
                eventName: AnalyticsEvents.homepage.upi_buynow_staticpage.name,
            },
            {
                label: "Card Protect",
                link: EXTERNAL_URLS.CARD_PROTECT,
                eventName: AnalyticsEvents.homepage.card_buynow_staticpage.name,
            },
            
            {
                label: "HealthGuard Plus",
                link: EXTERNAL_URLS.HEALTH_GUARD_PLUS,
                eventName: ""
            },
            {
                label: "Mobile Screen Secure",
                link: EXTERNAL_URLS.MOBILE_SCREEN_SECURE,
                eventName: AnalyticsEvents.homepage.mobile_buynow_staticpage.name,
            },
            {
                label: "Car Assistance",
                link: EXTERNAL_URLS.CAR_ASSISTANCE,
                eventName: AnalyticsEvents.homepage.car_assitance_buy_now_staticpage.name,
            },
            //{ label: "Extended Warranty", link: "blog" },
            //{ label: "Key Assistance", link: "blog" },
        ],
    },
    { label: "Claims", link: EXTERNAL_URLS.CONTACT },
    { label: "About Us", link: "about-us" },
    // { label: "Blogs", link: "blog" },
];
