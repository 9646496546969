const env = "PROD";

const appBaseUrl = () => {
  const url = {
    LOCAL: {
      CMS_URL: "",
      i24_APP_URL: "https://i24stage.in/",
      i24_GROUP_APP_URL: "https://group.i24stage.in/",
    },
    STAGING: {
      CMS_URL: "",
      i24_APP_URL: "https://i24stage.in/",
      i24_GROUP_APP_URL: "https://group.i24stage.in/",
    },
    QA: {
      CMS_URL: "",
      i24_APP_URL: "https://i24stage.in/",
      i24_GROUP_APP_URL: "https://group.i24stage.in/",
    },
    PROD: {
      CMS_URL: "",
      i24_APP_URL: "https://insure24.com/",
      i24_GROUP_APP_URL: "https://group.insure24.com/",
    },
  };

  return url[env];
};

export default appBaseUrl;
