"use client";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import logoSvg from "../../../public/logo.svg";
import logoImg from "../../../public/logo.png";
import insure24 from "../../../public/svg/insure24.svg";
import insure24svg from "../../../public/svg/insure24black.svg";
import chevronup from "../../../public/svg/chevron-up.svg";
import chevrondown from "../../../public/svg/chevron-down.svg";
import hamburgerIcon from "../../../public/svg/hamburger.svg";
import hamburgerBlackIcon from "../../../public/svg/hamburgerblack.svg";
import cars24 from "../../../public/svg/cars24.svg";
import cars24Color from "../../../public/svg/cars24Color.svg";
import crossIcon from "../../../public/svg/close.svg";
import styles from "./style.module.css";
// import Button from "../button";
import { menuItems } from "../navbar/constant";
import { EXTERNAL_URLS } from "@/constants/urls.constants";
import { NavigateToLink } from "@/utils/navigation";
import { Button } from "@cars24/turbo-web/lib/button";
import { sendAnalyticsData } from "@/services/google-analytics";
import { AnalyticsEvents } from "@/constants/appAnalyticsConstants";
interface NavbarProps {
  isSticky?: boolean;
}

export const MobileNavbar: React.FC<NavbarProps> = ({
  isSticky: propIsSticky,
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isSticky, setIsSticky] = useState(propIsSticky || false);
  const [activeMenuItemIndex, setActiveMenuItemIndex] = useState<number | null>(
    null
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  });

  useEffect(() => {
    if (propIsSticky !== undefined) {
      setIsSticky(propIsSticky);
    }
  }, [propIsSticky]);

  useEffect(() => {
    if (propIsSticky === undefined || propIsSticky === false) {
      const handleScroll = () => {
        setIsSticky(window.scrollY > 0);
      };
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [propIsSticky]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = ""; // Re-enable scrolling
    }
  }, [isMenuOpen]);

  const toggleMenu = () => {
    const currentPath = window.location.pathname;
    console.log(currentPath);

    // Only toggle sticky if the current path is not "/"
    if (currentPath == "/") {
      setIsSticky(!isMenuOpen); // Toggle sticky based on menu open state
    }

    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClick = (index: number) => {
    setActiveMenuItemIndex(activeMenuItemIndex === index ? null : index);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className={`${styles.navbar} ${isSticky ? styles.sticky : ""}`}>
      <div className={styles.navbarFlex}>
        <Link href="/">
          {/* <div className={styles.navbarLogo} onClick={closeMenu}> */}
          <div className={styles.navbarLogo}>
            <div>
              <Image
                src={isSticky ? logoImg : logoSvg}
                width={18}
                height={19}
                alt="Insure icon"
              />
            </div>
            <div>
              <div>
                <Image
                  src={isSticky ? insure24svg : insure24}
                  width={60}
                  height={19}
                  alt="Insure logo"
                />
              </div>
              <div>
                <Image
                  src={isSticky ? cars24Color : cars24}
                  width={26}
                  height={9}
                  alt="Insure logo"
                />
              </div>
            </div>
          </div>
        </Link>
        <div className={styles.navActions}>
          <Button
            type="button"
            label={"Login"}
            size="small"
            variant={isSticky ? "primary" : "secondary"}
            onClick={() => {
              sendAnalyticsData(
                AnalyticsEvents.homepage.login_staticpage.name,
                AnalyticsEvents.homepage.data
              );
              NavigateToLink(EXTERNAL_URLS.USER_LOGIN);
            }}
          />
          <Image
            src={
              isMenuOpen
                ? crossIcon
                : isSticky
                ? hamburgerBlackIcon
                : hamburgerIcon
            }
            width={24}
            height={24}
            alt="Menu icon"
            onClick={toggleMenu}
          />
        </div>
      </div>
      {isMenuOpen && (
        <div className={styles.mobileMenu}>
          {menuItems.map((item, index) => (
            <div key={index} className={styles.mobileMenuItem}>
              {item.link && !item.subItems ? (
                // <Link
                //   href={item.link}
                // onClick={() => {
                //   item.label === "About Us" &&
                //     sendAnalyticsData(
                //       AnalyticsEvents.homepage.about_us_staticpage.name,
                //       AnalyticsEvents.homepage.data
                //     );
                //   item.label === "Claims" &&
                //     sendAnalyticsData(
                //       AnalyticsEvents.homepage.claims_staticpage.name,
                //       AnalyticsEvents.homepage.data
                //     );
                //   closeMenu();
                // }}
                // >
                // {item.label}
                // </Link>
                <div
                  onClick={() => {
                    item.label === "About Us" &&
                      sendAnalyticsData(
                        AnalyticsEvents.homepage.about_us_staticpage.name,
                        AnalyticsEvents.homepage.data
                      );
                    item.label === "Claims" &&
                      sendAnalyticsData(
                        AnalyticsEvents.homepage.claims_staticpage.name,
                        AnalyticsEvents.homepage.data
                      );
                    NavigateToLink(item.link, "_self");
                  }}
                >
                  {item.label}
                </div>
              ) : (
                <div
                  className={`${styles.menuLabel} ${
                    isSticky || activeMenuItemIndex === index
                      ? styles.scrollColor
                      : ""
                  }`}
                  onClick={() => handleMenuClick(index)}
                >
                  {item.label}
                  {item.subItems && (
                    <Image
                      src={
                        activeMenuItemIndex === index ? chevronup : chevrondown
                      }
                      width={24}
                      height={24}
                      alt="Chevron icon"
                    />
                  )}
                </div>
              )}
              {item.subItems && activeMenuItemIndex === index && (
                <div className={styles.mobileSubMenu}>
                  {item.subItems.map((subItem, subIndex) => (
                    <Link
                      href={subItem.link}
                      key={subIndex}
                      onClick={() => {
                        subItem.eventName &&
                          sendAnalyticsData(
                            subItem.eventName,
                            AnalyticsEvents.homepage.data
                          );
                        closeMenu();
                      }}
                    >
                      {subItem.label}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
